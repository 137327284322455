export default function NewsCard({ title, image_url, description, published_time }) {

    return (
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <div>
                <img width='150px' src={image_url} alt={title} />
            </div>
            <div>
                <h3>{title}</h3>
                <p>{description}</p>
                <p>{published_time}</p>
            </div>
        </div>
    )
}
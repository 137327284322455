import { useEffect, useState } from "react";
import NewsCard from "./NewsCard";

const sources = [
    {
        id: 1,
        name: "Source 1",
        api: `https://feedapiworker.indira-gollapudi.workers.dev/`
    },
    {
        id: 2,
        name: "Source 2",
        api: `https://feedapi1worker.indira-gollapudi.workers.dev/`
        // api: "https://api.rss2json.com/v1/api.json?rss_url=https://tv9telugu.com/latest-news/feed"
    }
]

export default function NewsContainer() {
    const [newsData, setNewsData] = useState([])
    const [selectedSource, setSelectedSource] = useState(sources[1])
    const [loading, setLoading] = useState(false)

    const normalizeData = (data, sourceID) => {
        switch (sourceID) {
            case 1:
                return data.map(item => ({
                    title: item.title.rendered,
                    imageUrl: item.yoast_head_json.og_image[0].url,
                    description: item.excerpt.rendered,
                    publishedTime: item.yoast_head_json.article_published_time,
                }));
            case 2:
                return data.map(item => ({
                    title: item.title,
                    imageUrl: item.imageSrc,
                    description: item.description,
                    publishedTime: item.pubDate,
                }));
            default:
                return [];
        }
    };

    const fetchNewsData = async () => {
        setLoading(true)
        try {
            let response = await fetch(selectedSource.api);
            let data = await response.json();
            const normalizedData = normalizeData(data, selectedSource.id)
            console.log(data)
            setNewsData(normalizedData)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.error('Error fetching data:', e);
        }
    };

    useEffect(() => {
        fetchNewsData();
        console.log("clicked")
    }, [selectedSource]);

    return (
        <div>
            <div style={{ display: "flex", gap: "15px" }}>
                {sources.map(source => (
                    <button
                        style={{
                            padding: "10px",
                            cursor: "pointer",
                        }}
                        onClick={() => setSelectedSource(source)}
                    >
                        {source.name}
                    </button>
                ))}
            </div>
            {loading ? (
                <h1>Loading</h1>
            ) : (
                newsData && newsData.map(newsItem => (
                    < NewsCard
                        title={newsItem.title}
                        image_url={newsItem.imageUrl}
                        description={newsItem.description}
                        published_time={newsItem.publishedTime}
                    />
                ))
            )}
        </div>
    );
}

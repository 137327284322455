import './App.css';
import NewsContainer from './components/NewsContainer';

function App() {
  return (
    <div>
      <NewsContainer />
    </div>
  );
}

export default App;
